@tailwind base;
@tailwind components;
@tailwind utilities;

.shadow-lg-rightDetailsPanel {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ant-table-filter-trigger {
  color: white !important;
}

.shadow-lg-leftQuestionPanel {
  border-radius: 10px;
}

.shadow-md {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
}

/* Apply shadow only for screens wider than 768px */
@media (min-width: 649px) {
  .shadow-lg-leftQuestionPanel {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}

.ant-btn-primary {
  background-color: orangered;
  border-color: orangered;
  /* color: white; */
  color: black;
}

.ant-btn-primary:hover {
  background-color: orangered;
  border-color: orangered;
  color: green;
}

.ant-btn-primary:focus {
  background-color: orangered;
  border-color: orangered;

}

/* Scroll bar hide */
/* Hide scrollbar */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}


.ant-table-cell.custom-header-class {
  border-color: transparent !important;
  /* Set border color to transparent */
}


.ant-spin-dot-item {
  background-color: #2A3864 !important;
  /* Change to your desired color */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}